// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("E:\\ramesh\\work\\ramesh-website\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-template-jsx": () => import("E:\\ramesh\\work\\ramesh-website\\src\\templates\\post-template.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-tag-template-jsx": () => import("E:\\ramesh\\work\\ramesh-website\\src\\templates\\tag-template.jsx" /* webpackChunkName: "component---src-templates-tag-template-jsx" */),
  "component---src-templates-category-template-jsx": () => import("E:\\ramesh\\work\\ramesh-website\\src\\templates\\category-template.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("E:\\ramesh\\work\\ramesh-website\\src\\templates\\page-template.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-pages-404-jsx": () => import("E:\\ramesh\\work\\ramesh-website\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-categories-jsx": () => import("E:\\ramesh\\work\\ramesh-website\\src\\pages\\categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-index-jsx": () => import("E:\\ramesh\\work\\ramesh-website\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-tags-jsx": () => import("E:\\ramesh\\work\\ramesh-website\\src\\pages\\tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "E:\\ramesh\\work\\ramesh-website\\.cache\\data.json")

